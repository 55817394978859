.htmlContainer {
    margin: 0 !important;
}

.footerText {
    padding: 0 !important;
    margin: 0 !important;
}

.popup {
    display: none;
    position: relative;
    box-sizing: border-box;
    grid-template-columns: minmax(0, 100%);
    width: 32em;
    max-width: 100%;
    padding: 0 0 1.25em;
    border: none;
    border-radius: 1rem;
    background: #fff;
    color: #545454;
    font-family: inherit;
    font-size: 1rem;
}

.bezel {
    border-radius: 10px !important;
}

.right {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
}

.left {
    margin-left: 0.5rem;
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    transform: scaleX(-1);
}

.arrow {
    position: absolute;
    bottom: 0;
    margin-left: 0px;
    width: 2rem;
    height: 2rem;
    background-size: contain;
    top: 15px;
}

.second {
    margin-left: 1rem;
}

.next {
    opacity: 0.6;
    mask-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHN0eWxlPi5zdDB7ZmlsbDojZmZmfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMxOS4xIDIxN2MyMC4yIDIwLjIgMTkuOSA1My4yLS42IDczLjdzLTUzLjUgMjAuOC03My43LjZsLTE5MC0xOTBjLTIwLjEtMjAuMi0xOS44LTUzLjIuNy03My43UzEwOSA2LjggMTI5LjEgMjdsMTkwIDE5MHoiLz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzE5LjEgMjkwLjVjMjAuMi0yMC4yIDE5LjktNTMuMi0uNi03My43cy01My41LTIwLjgtNzMuNy0uNmwtMTkwIDE5MGMtMjAuMiAyMC4yLTE5LjkgNTMuMi42IDczLjdzNTMuNSAyMC44IDczLjcuNmwxOTAtMTkweiIvPjwvc3ZnPg==);
    background-color: cadetblue;
}

@keyframes bounceAlpha {
    0% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }

    25% {
        opacity: 0;
        transform: translateX(10px) scale(0.9);
    }

    26% {
        opacity: 0;
        transform: translateX(-10px) scale(0.9);
    }

    55% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }
}

.bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.arrow.first.bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-delay: 0.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.right:hover .arrow {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.right:hover .arrow.first {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-delay: 0.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.left:hover .arrow {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.left:hover .arrow.first {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-delay: 0.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.custom-select-container {
    display: inline-block;
    min-width: 25vw;
    max-width: 25vw;
    text-align: center;
}

@media screen and (max-width: 860px) {
    .custom-select-container {
        display: inline-block;
        min-width: 80vw;
        max-width: 80vw;
        padding-top: 1rem;
        text-align: center;
    }   
}

.selected-text {
    background-color: #FFF;
    color: #a2a2a2;
    font-weight: 500;
    text-align: left;
    padding: 1rem;
    border-radius: 10px 10px 0px 0px;
}

.selected-text::after {
    content: "";
    position: absolute;
    border: 0.8rem solid transparent;
    -webkit-mask-size: 100%;
    margin-left: 2rem;
    mask-size: 100%;
    border-color: transparent;
    background-color: rgb(200, 200, 200);
    mask-image: url(https://admin.iniciativaecos.com/assets/d9020d50-7a9b-4cf6-9153-7e0d6b31c30f);
}

.selected-text.active::after {
    top: 8px;
    border-color: transparent transparent #fff transparent;
}

.select-options {
    position: relative;
    width: 100%;
}

.li-opt {
    list-style-type: none;
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 1rem;
    background: #FFF;
    border-bottom: 2px solid #DDD;
    cursor: pointer;
}

.li-opt:hover {
    background-color: #32a6a6;
    color: #ffffff;

    .icon-ta {
        background: white !important;
    }
}

.icon-ta {
    width: 2.5rem;
    height: 2.5rem;
}