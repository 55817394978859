@media screen and (max-width: 860px) {
    .btnL1 {
        font-size: 0.8rem;
        padding: 0.8rem;
    }

    .resp {
        padding-inline: 2rem;
    }

    .mresp {
        margin-inline: 2rem;
    }

    .custom-select-container {
        min-width: 80vw;
        max-width: 80vw;
        margin-bottom: 1rem;
    }

    .gridSelect {
        grid-template-columns: 1fr;
    }
    
    .imgCarousel {
        max-height: 80vw;
    }

    .h1text {
        margin-bottom: 1.5rem;
        font-size: 0.8rem !important;
    }

    .textCar {
        top: 40%;
    }
}

.htmlCont {
    margin: 0 !important;
}

.btnL {
    transition-duration: 0.4s;
    border: transparent;
    padding: 1rem 4rem;
    border-radius: 10px 10px 10px 0px;
    width: 25vw;
    color: white;
    font-size: 0.8rem;
    font-weight: 500;
    text-decoration: none;
}

.btnL:hover {
    color: #000 !important;
}

.btnL1 {
    transition-duration: 0.4s;
    border: transparent;
    padding: 1rem;
    border-radius: 10px 10px 10px 0px;
    width: 25vw;
    color: white;
    font-weight: 500;
    text-decoration: none;
}

.btnL1:hover {
    color: black !important;
}

.rad {
    border-radius: 10px !important;
}

.resp {
    margin-bottom: -5rem;
    background: #6e2f85;
    padding: 5rem;
}

.mresp {
    margin-inline: 5rem;
}

.icon-t {
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 2.5rem;
    height: 2.5rem;
}

.icon-ta {
    width: 2.5rem;
    height: 2.5rem;
}

.title {
    text-align: left;
}

.custom-select-container {
    display: inline-block;
    min-width: 25vw;
    max-width: 25vw;
    text-align: center;
}

.popup {
    display: none;
    position: relative;
    box-sizing: border-box;
    grid-template-columns: minmax(0,100%);
    width: 32em;
    max-width: 100%;
    padding: 0 0 1.25em;
    border: none;
    border-radius: 1rem;
    background: #fff;
    color: #545454;
    font-family: inherit;
    font-size: 1rem;
}

.footer {
    display: flex;
    position: relative;
    margin-block: -1rem;
    padding: 2rem 0rem;
    top: 40%;
    width: 100%;
    border-radius: 0 0 1rem 1rem;
}

.footerP {
    padding: 0 !important;
    margin: 0 !important;
}

.selected-text {
    background-color: #FFF;
    color: #a2a2a2;
    font-weight: 500;
    padding: 1rem;
    border-radius: 10px 10px 0px 0px;
}

.selected-text::after {
    content: "";
    position: absolute;
    border: 0.8rem solid transparent;
    -webkit-mask-size: 100%;
    margin-left: 2rem;
    mask-size: 100%;
    border-color: transparent;
    background-color: rgb(200, 200, 200);
    mask-image: url(https://admin.iniciativaecos.com/assets/d9020d50-7a9b-4cf6-9153-7e0d6b31c30f);
}

.selected-text.active::after {
    top: 8px;
    border-color: transparent transparent #fff transparent;
}

ul {
    margin-left: 0;
    padding: 0;
    text-align: left;
}

.select-options {
    position: relative;
    width: 100%;
}

.li-opt {
    list-style-type: none;
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 1rem;
    background: #FFF;
    border-bottom: 2px solid #DDD;
    cursor: pointer;
}

.li-opt:hover {
    background-color: #32a6a6;
    color: #ffffff;

    .icon-ta {
        background: white !important;
    }
}

.gridSelect {
    display: grid; 
    grid-template-columns: repeat(3, 1fr); 
    grid-column-gap: 1rem;
}

.imgCarousel {
    max-height: 30vw;
    object-fit: cover;
}

.h1text {
    color: white !important;
    text-shadow: 1px 2px 4px #313131; 
    margin-bottom: 2rem;
    margin-left: 2rem;
    text-align: left;
}

.textCar {
    position: absolute;
    top: 35%;
    left: 15%;
    max-width: 50vw;
}
