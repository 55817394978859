.featuredOption {
  display: block;
  font-weight: 500;
  text-decoration: none;
  position: relative;
  padding: 0.8rem;
  background-color: #6e2f85;
  text-align: center;
  color: #fff;
  font-size: medium;
}

.featuredOptionContent {
  padding-block: 2rem;
  padding-inline: 4rem;
  border: 4px solid #6e2f85;
}

.option {
  display: block;
  font-weight: 500;
  text-decoration: none;
  position: relative;
  padding: 0.8rem;
  background-color: #479197;
  text-align: center;
  color: #fff;
  font-size: medium;
}

.optionContent {
  padding: 1rem;
  border: 4px solid #479197;
}

.listTerms li {
  list-style-type: disc;
  padding-block: 0.5rem;
}

.gapOption {
  padding-bottom: 1rem;
}
